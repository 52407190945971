/* .image {
    margin: 0 auto;
    height: 86% !important;
    width: auto;
} */
img {
    display: block;
    width: 100%;
    height: 100%;
}

.carousel {
    display: flex;
    justify-content: center;
    /* height: 20vh !important; */
}
.carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden;
    height: 77vh !important;
}
.carousel .slide {
    height: 100vh;
}
.carousel .slide img {
    max-height: 100%;
    width: auto;
}
.image {
    margin: 0 auto;
    height: 100%;
    width: auto;
}
.carousel.carousel-slider {
    width: 100%;
    height: 80vh;
}
.carousel .thumbs-wrapper {
    margin: -100px;
    overflow: hidden;
}
.carousel .control-dots {
    z-index: 0;
}
.carousel.carousel-slider .control-arrow {
    padding: 60px;
}
* {
    -ms-overflow-style: none;
}
/* ::-webkit-scrollbar {
    display: none;
} */
::-webkit-file-upload-button {
    cursor:pointer;
}
.grecaptcha-badge{
    z-index: 10000;
}
