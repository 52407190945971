@font-face {
    font-family: "outfit_regular";
    font-style: normal;
    font-weight: normal;
    src: url(../fonts/Outfit-Regular.woff) format("woff");
}

@font-face {
    font-family: "outfit_medium";
    font-style: normal;
    font-weight: normal;
    src: url(../fonts/Outfit-Medium.woff) format("woff");
}

@font-face {
    font-family: "outfit_bold";
    font-style: normal;
    font-weight: normal;
    src: url(../fonts/Outfit-Bold.woff) format("woff");
}
@font-face {
    font-family: "outfit_light";
    font-style: normal;
    font-weight: normal;
    src: url(../fonts/Outfit-Light.woff) format("woff");
}
* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: 0 0;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-family: outfit_regular;
}
body::-webkit-scrollbar {
    display: none;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="text"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input[type="button"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
ol,
ul,
li {
    list-style: none;
    margin-bottom: 0 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0 !important;
}
blockquote {
    quotes: none;
}
blockquote:after,
blockquote:before {
    content: "";
    content: none;
}
del {
    text-decoration: line-through;
}
address,
article,
aside,
footer,
header,
nav,
section {
    display: block;
}
body {
    font-family: "outfit_regular";
    font-size: 17px;
    overflow-x: hidden;
}

body::-webkit-scrollbar {
    display: none;
}
input,
select,
textarea {
    font-family: "outfit_regular";
}
textarea {
    outline: none;
}
textarea:focus {
    border: 1px solid #0884df !important;
}
input:focus-visible {
    outline-color: #0884df;
    /* border: 1px solid #0884df !important; */
}
input.rec:focus-visible {
    outline: none;
}
#captcha .rec {
    border: none;
}
html {
    overflow: scroll !important;
    scroll-behavior: smooth !important;
}
html.modal-enabled {
    overflow: hidden !important;
}
a {
    text-decoration: none !important;
}
.submit:hover,
a:hover {
    -moz-opacity: 0.85;
    -khtml-opacity: 0.85;
    opacity: 0.85;
}
.thumb {
    display: block;
}
.thumb img {
    display: block;
    width: 100%;
}
p {
    line-height: 1.6em;
    font-size: 16px;
    color: #868686;
    font-family: "outfit_regular";
}
#main {
    margin-left: 57px;
}
.OtpInput {
    margin-right: 22px;
    width: 60px !important;
    height: 62px;
    font-size: 24px;
    font-family: "outfit_medium";
    color: #0b7add;
    border: 1px solid #c6c6c6;
    outline-color: #0b7add;
    border-radius: 8px;
}
.error {
    margin-right: 22px;
    width: 60px !important;
    height: 62px;
    font-size: 24px;
    font-family: "outfit_medium";
    color: #0b7add;
    border: 1px solid #e46b6b;
    outline-color: #e46b6b;
    border-radius: 8px;
}
.OtpInput:last-child() {
    margin-right: 0;
}
.OtpStyle {
    width: 80%;
}
.errorstyle {
    width: 80%;
}
@media all and (max-width: 1080px) {
    .OtpInput {
        width: 50px !important;
        height: 52px;
    }
    .error {
        width: 50px !important;
        height: 52px;
    }
}
@media all and (max-width: 768px) {
    .OtpInput {
        width: 45px !important;
        height: 48px;
    }
    .error {
        width: 45px !important;
        height: 48px;
    }
}
@media all and (max-width: 640px) {
    .OtpInput {
        width: 40px !important;
        height: 45px;
        margin-right: 18px;
        font-size: 19px;
    }
    .error {
        width: 40px !important;
        height: 45px;
        margin-right: 18px;
        font-size: 19px;
    }
}
@media all and (max-width: 480px) {
    .OtpInput {
        width: 50px !important;
        height: 50px;
        margin-right: 20px;
        font-size: 20px;
    }
    .error {
        width: 50px !important;
        height: 50px;
        margin-right: 20px;
        font-size: 20px;
    }
}

.tooltip---tooltip-inner---AMsNF {
    background: rgba(255, 255, 255, 0) !important;
    box-shadow: none !important;
    border: none !important;
}
.map-container {
    height: 80vh !important;
}
.places-container {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 300px;
}
@media all and (max-width: 1280px) {
    .places-container {
        top: 53px;
        left: 24%;
    }
}
@media all and (max-width: 1080px) {
    .places-container {
        left: 23%;
    }
}
@media all and (max-width: 980px) {
    .places-container {
        left: 25%;
    }
}
@media all and (max-width: 640px) {
    .places-container {
        left: 28%;
    }
}

.map-container {
    width: 100%;
    height: 100vh;
}
@media all and (max-width: 1380px) {
    .map-container {
        height: 85vh;
    }
}
@media all and (max-width: 980px) {
    .map-container {
        height: 50vh;
    }
}

.combobox-input {
    width: 100%;
    padding: 0.5rem;
    background-color: #fff;
    margin-left: 50px;
}
@media all and (max-width: 1080px) {
    .combobox-input {
        width: 75%;
    }
}
@media all and (max-width: 480px) {
    .combobox-input {
        width: 90%;
        margin-left: 60px;
        margin-top: 90px;
    }
    .places-container {
        position: absolute;
        top: -35px;
        left: 34%;
        transform: translateX(-50%);
        z-index: 10;
        width: 300px;
    }
}
@media all and (max-width: 360px) {
    .combobox-input {
        width: 50%;
        margin-left: 93px;
    }
}

.arrow span {
    display: block;
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #06a8ff;
    border-right: 5px solid #06a8ff;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}
@media (max-width: 980px) {
    .arrow span {
        margin: -10px;
        margin-right: 30px;
    }
}
@media (max-width: 980px) {
    .arrow span {
        display: none;
    }
}
.arrow span:nth-child(2) {
    animation-delay: -0.2s;
}
.arrow span:nth-child(3) {
    animation-delay: -0.4s;
}
@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}
.input-drop {
    width: 100%;
    margin-left: 50px;
    background: #fff9f9;
    outline-color: #0884df !important;
    max-height: 250px;
    overflow-y: scroll !important;
    padding-left: 0 !important;
}
.input-option {
    padding: 12px 18px;
    cursor: pointer;
    transition: 0.4s ease;
    outline-color: #0884df !important;
}
.input-option:hover {
    background-color: #bcbcbce2;
    color: #fff;
    transition: 0.4s ease;
}
.input-drop::-webkit-scrollbar {
    /* display: none; */
}
.target {
    position: absolute;
    border-bottom: 4px solid transparent;
    z-index: -1;
    transform: translateX(-60px);
}
.target {
    transition: all 0.35s ease-in-out;
}
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}
.nav-item {
    color: #83818c;
    padding: 20px;
    text-decoration: none;
    transition: 0.3s;
    margin: 0 6px;
    z-index: 1;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 500;
    position: relative;
}
.nav-item::before {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #dfe2ea;
    border-radius: 8px 8px 0 0;
    opacity: 0;
    transition: 0.3s;
}
.nav-item:not(.is-active):hover::before {
    opacity: 1;
    bottom: 0;
}
.nav-item:not(.is-active):hover {
    color: #333;
}
.nav-indicator {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    transition: 0.4s;
    height: 5px;
    z-index: 1;
    border-radius: 8px 8px 0 0;
}
@media (max-width: 580px) {
    .nav {
        overflow: auto;
    }
}
.wrapper {
    width: 88%;
    margin: 0 auto;
}
@media all and (max-width: 480px) {
    .wrapper {
        width: 90%;
    }
}
.three {
    width: 23.8%;
}
.col {
    display: none;
    justify-content: flex-end;
    margin: 1% 0 1% 1.6%;
}
@media all and (max-width: 640px) {
    .col {
        display: flex;
    }
}
.col:first-of-type {
    margin-left: 0;
}
.hamburger .line {
    width: 35px;
    height: 5px;
    background-color: #0d5fa4;
    display: block;
    margin: 8px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
@media all and (max-width: 640px) {
    .hamburger .line {
        width: 25px;
        height: 3px;
        margin: 4px auto;
    }
}
@media all and (max-width: 480px) {
    .hamburger .line {
        width: 23px;
        height: 3px;
        margin: 4px auto;
    }
}
@media all and (max-width: 360px) {
    .hamburger .line {
        width: 20px;
        height: 3px;
        margin: 4px auto;
    }
}
.is-active .line {
    width: 35px;
    height: 5px;
    background-color: #0d5fa4;
    display: block;
    margin: 8px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
@media all and (max-width: 640px) {
    .is-active .line {
        width: 20px;
        height: 3px;
    }
}
@media (max-width: 480px) {
    .is-active .line {
        width: 20px;
        height: 3px;
    }
}
@media (max-width: 360px) {
    .is-active .line {
        width: 20px;
    }
}

.hamburger:hover {
    cursor: pointer;
}

#hamburger-1.is-active span.line:nth-child(2) {
    opacity: 0;
}
#hamburger-1.hamburger span.line:nth-child(2) {
    background-color: #02a9ae;
    width: 25px;
    transform: translateX(5px);
}
@media all and (max-width: 640px) {
    #hamburger-1.hamburger span.line:nth-child(2) {
        width: 20px;
        transform: translateX(3px);
    }
}
@media all and (max-width: 480px) {
    #hamburger-1.hamburger span.line:nth-child(2) {
        width: 15px;
        transform: translate(3px);
    }
}
@media all and (max-width: 360px) {
    #hamburger-1.hamburger span.line:nth-child(2) {
        transform: translateX(4px);
        width: 12px;
    }
}
#hamburger-1.is-active span.line:nth-child(1) {
    -webkit-transform: translateY(13px) rotate(45deg);
    -ms-transform: translateY(13px) rotate(45deg);
    -o-transform: translateY(13px) rotate(45deg);
    transform: translateY(13px) rotate(45deg);
}
@media all and (max-width: 680px) {
    #hamburger-1.is-active span.line:nth-child(1) {
        transform: translateY(9px) rotate(45deg);
    }
}
#hamburger-1.is-active span.line:nth-child(3) {
    -webkit-transform: translateY(-13px) rotate(-45deg);
    -ms-transform: translateY(-13px) rotate(-45deg);
    -o-transform: translateY(-13px) rotate(-45deg);
    transform: translateY(-13px) rotate(-45deg);
}
/* PAGINATION */

.pagination {
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: #48538d;
    flex-wrap: wrap;
}
.pagination a {
    padding: 4px 14px;
    border-radius: 5px;
    color: #161619;
    background-color: #fff;
    font-size: 13px;
    margin: 0 5px 5px;
    display: inline-block;
}
.pagination li.break a {
    display: inline-block;
}
.previous .pagination__link {
    background-color: unset;
    color: #48538d;
}
.next .pagination__link {
    background-color: unset;
    color: #48538d;
}
.pagination__link {
    font-weight: bold;
}
.pagination__link--active a {
    color: #fff !important;
    background: #48538d;
}
.pagination__link--disabled a {
    cursor: not-allowed;
}
.active_link_classname {
    color: #fff;
    background: #48538d;
}

/* pagination_ends */

/* captcha */

#reload_href {
    color: yellow !important;
    position: absolute;
    background-image: url("../images/icons8-reset-24.png");
    /* top: 0px;
	right: 17px; */
    overflow: hidden;
    background-size: 23px;
    width: 22px;
    height: 22px;
    top: 20%;
    background-repeat: round;
    right: 5%;
    /* opacity: 0; */
}
/* close button */
.profile-pic {
}

.profile-pic:hover {
}
.profile-image:hover {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    color: #fff;
    transition: all 0.3s ease;
    text-decoration: none;
}
.profile-pic span {
    display: inline-block;
    padding-top: 12px;
    padding-bottom: 4.5em;
}

form input[type="file"] {
    display: none;
    cursor: pointer;
}
input[type="file"] {
    cursor: pointer !important;
}
.change:hover {
    opacity: 0.7;
}

/* select option in home page */

.select-option {
    margin-right: 20px;
    letter-spacing: 1px;
    font-family: 'outfit_light';
    font-size: 17px;
}
@media all and (max-width: 768px) {
    .select-option {
        margin-right: 10px;
    }
}
@media all and (max-width: 768px) {
    .select-option {
        margin-right: unset;
    }
}
.select-option .css-1s2u09g-control {
    height: 50px !important;
    box-shadow: none !important;
    border-color: #022334 !important;
    outline-color: #0884df !important;
    color: #707070 !important;
    background: #042941 !important;
    width: 100%;
    margin-bottom: 7px;
    cursor: pointer;
}
.css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 7px;
    height: 55px;
}
.select-option .css-1pahdxg-control:hover {
    box-shadow: none !important;
    border-color: #0884df !important;
    color: #707070 !important;
    outline-color: #0884df !important;
}
.select-option .css-1pahdxg-control {
    height: 50px !important;
    /* border-color: #022334 !important; */
    outline-color: #0884df !important;
    box-shadow: none !important;
    background: #042941 !important;
}
.select-option .css-qc6sy-singleValue {
    color: #eeeeee !important;
}

/* add enquiry modal */

.css-1s2u09g-control {
    height: 50px !important;
    box-shadow: none !important;
    /* border-color: #bebebe !important; */
    color: #707070 !important;
    outline-color: #0884df !important;
    cursor: pointer;
    background: #F5F7FB !important;
    border: 1px solid #E6EDFB;
    border-radius: 2px;
}
.css-1pahdxg-control:hover {
    /* height: 50px !important; */
    box-shadow: none !important;
    outline-color: #0884df !important;
    /* border-color: #bebebe !important; */
    color: #707070 !important;
}
.css-1pahdxg-control {
    height: 50px !important;
    outline-color: #0884df !important;
    /* border-color: #bebebe !important; */
    box-shadow: none !important;
    outline-color: #0884df !important;
}
.css-qc6sy-singleValue {
    color: #707070 !important;
    /* color: #fff !important; */
    font-size: 14px !important;
}
.css-1hb7zxy-IndicatorsContainer {
    cursor: pointer;
}
.rc-anchor{
    background: #fff9f9;
}
/* .react-datepicker-popper{
    position: absolute !important;
      inset: auto auto 0px 0px !important;
      transform: translate3d(0px, 5px, 0px) !important;
  } */
  /* .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
    border: 8px solid transparent;
    box-sizing: content-box;
    content: "";
    height: 0;
    left: -8px;
    bottom: 1px;
    position: absolute;
    width: 1px;
    z-index: -1;
  } */
  /* .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
    bottom: -8px!important;
    left:90px!important;
    top: unset;
    transform: rotate(180deg)!important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
    border-bottom: none;
    border-top-color: #aeaeae;
  }
  .react-datepicker-popper[data-placement^=bottom-start]{
    inset: auto auto 0px -30px !important;
  }
  .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:after{
    border: 8px solid transparent;
    box-sizing: content-box;
    content: "";
    height: 0;
    left: -8px;
    position: absolute;
    width: 1px;
    z-index: -1;
    border-bottom: none;
    border-top-color: #fff;
    bottom: 0;
} */
.css-1okebmr-indicatorSeparator {
    display: none;
}